<template>
  <transition name="fade" mode="out-in">
    <div class="accessibility-page-container">
      <nav-component></nav-component>
      <div class="accessibility-hero">
        <h1>ACCESSIBILITY POLICY</h1>
      </div>
      <colours-bar></colours-bar>
      <div class="body-container">
        <h1>Purpose</h1>

        <p>CarltonOne Engagement is committed to diversity, inclusion and accessibility for persons with disabilities.
        The purpose of this policy is to establish policies, practices and procedures to ensure CarltonOne
        Engagement is accessible to people with disabilities in accordance with Accessibility for Ontarians with
        Disabilities Act, 2005 (the "Act").</p>

        <h1>Policy Statement</h1>

        <p>CarltonOne Engagement is committed to ensuring equal access, service, and participation for people with
        disabilities. We are committed to treating people with disabilities in a way that allows them to maintain
        their dignity and independence.</p>

        <h1>Application</h1>

        <p>This policy applies to all CarltonOne Engagement employees in Ontario.</p>

        <h1>General Principles and Practices</h1>
        <p>CarltonOne Engagement shall meet its duties and responsibilities under the “Act” by adhering to the following principles and practices:</p>

        <ol class="first-list">
          <li>
            <strong>Communication</strong><br>
            CarltonOne Engagement is committed to communicating with persons with disabilities in ways that consider their disability.
          </li>
          <li>
            <strong>Training of employees</strong><br>
            CarltonOne Engagement will provide training to all its workers regarding the provisions and ways
            to ensure accessible programs, goods, services, and workplace.
            <br>
            Training will occur on an ongoing basis and whenever changes are made to relevant policies,
            practices and procedures.
          </li>

          <li>
            <strong>Assistive devices</strong><br>
            CarltonOne Engagement permits persons with disabilities to use their personal assistive devices
            while on its premises.
          </li>
          <li>
            <strong>Service animals and support persons</strong><br>
            CarltonOne Engagement welcomes onto its premises people with disabilities and their service
            animals and support persons upon whom persons with disabilities rely.
          </li>
          <li>
            <strong>Notice of temporary disruption</strong><br>
            CarltonOne Engagement will give notice of temporary disruptions to services or facilities used by
            persons with disabilities including the reason for the disruption, the anticipation of the disruption
            and any alternative facilities or services that may be available. The notice shall be posted in a
            conspicuous place on the premises.
          </li>
        </ol>

        <h1>Procedure</h1>

        <p>To request accommodation or assistance for any good or service, contact CarltonOne Engagement by phone or email.</p>

        <ol>
          <li>
            <strong>Feedback process</strong><br>
            CarltonOne Engagement encourages feedback regarding how it provides goods and services to persons with disabilities. This can be provided via the following methods:
            <ul>
              <li>
                E-mail:<br>
                through the “Talk to us” link on our website or by e-mail: info@carltonone.com or HumanResources@carltonone.com
              </li>
              <li>
                Mail:<br>
                Attn: Human Resources<br>
                60 Columbia Way, 9th Floor<br>
                Markham, ON L3R 0C9
              </li>
              <li>
                Phone:<br>
                866.451.2225
              </li>
            </ul>
          </li>
          <li>
            <strong>Modifications to this or other policies</strong><br>
            CarltonOne Engagement is committed to ensuring that its policies, practices and procedures are consistent with and promote the core principles of dignity, independence, integration and equal opportunity. Any policy that does not respect and promote the dignity and independence of persons with disabilities will be modified or removed.
          </li>
          <li>
            <strong>Policy Review</strong><br>
            CarltonOne Engagement will maintain the Accessibility Plan & Policy outlining the Company’s strategy to prevent and remove barriers from its workplace and to meet its requirements under the “Act”.
          </li>
          <li>
            <strong>Compliance</strong><br>
            Employees are expected to comply with all parts of this policy. Failure to comply will result in discipline, up to and including termination for cause.
          </li>
        </ol>
      </div>
      <footer-component></footer-component>
    </div>
  </transition>
</template>

<script>
  export default {
    data: function() {
      return {
        name: 'privacy-policy'
      }
    }
  }
</script>

<style>
  /*HEADER/HERO*/
  .accessibility-page-container { background-color: #f1f2f2; }
  .accessibility-hero {
    background-image: url(../assets/img/privacy-hero.jpg);
    background-position: center;
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .accessibility-hero h1 {
    color: #fff;
    padding-top: 35px;
    font-size: 26px;
    letter-spacing: 6px;
    font-weight: 300;
  }

  .body-container {
    width: 80%;
    max-width: 1200px;
    margin: 60px auto;
  }

  .body-container * { color: #636466; }
  .body-container h1 {
    font-weight: 300;
    color: #636466;
    margin-bottom: 20px;
    font-size: 46px;
  }

  ol li { margin-bottom: 10px; }


</style>